<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <!-- ************************** OVERVIEW ***************************** -->
        <section id="overview">
          <i18n path="overview.paragraph" tag="div" class="bsat__paragraph">
            <template #link>
              <a @click="goToTab(3)">{{ $t("overview.link") }}</a>
            </template>
          </i18n>
        </section>

        <!-- ******************** SUMMARY *********************** -->

        <!-- <div class="bsat__subtitle mb-4">{{ $t("summary.title") }}</div>

        <ul class="bsat__paragraph">
          <li
            v-for="(item, i) in summaryUnorderedList"
            :key="i"
            class="my-1"
            v-text="item"
          ></li>
        </ul> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>
        <!-- ************************* RECOMMENDED RESOURCES ******************************* -->
        <div class="bsat__subtitle mb-4">
          {{ $t("recomendedResources.title") }}
        </div>

        <!-- <div class="bsat__paragraph mt-n3 mb-6">
          {{ $t("recommendedResourcesIntro") }}
        </div> -->

        <ul class="ml-0 ml-sm-4 bsat__ul">
          <li>
            <div>
              <i18n
                path="recomendedResources.paragraph.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(2)">{{
                    $t("recomendedResources.paragraph.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recomendedResources.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link1>
                  <a href="/new?phase=3&step=2">{{
                    $t("recomendedResources.paragraph2.link1")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recomendedResources.paragraph3.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a href="/new?phase=4&step=1">{{
                    $t("recomendedResources.paragraph3.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recomendedResources.paragraph4.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a href="/new?phase=4&step=2">{{
                    $t("recomendedResources.paragraph4.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div class="bsat__paragraph">
              {{ $t("recomendedResources.paragraph5") }}
            </div>
          </li>
        </ul>
      </div>
      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group.",
          "Analysis of collected information during roadmap definition."
        ]
      },
      "task": {
        "text": "{title}: Draft road-map",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Drafting group: designated technical expert from involved ministries or governmental departments",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "The roadmap of the regional bioeconomy strategy will be drafted by the designated group (as explained in Step 1, {link}) and supported/feedback . At this stage, some regions may prefer to complement and add the roadmap before finalising the document to the draft of the strategy. Others may decide to maintain these two documents separated to facilitate the update of roadmap separately in case of being necessary. In any case, the final version of the strategy and roadmap should go over several rounds of feedback internally (within members of the strategy development working group), before going through a final consultation process.",
      "link": "Phase 3"
    },
    "recomendedResources": {
      "paragraph": {
        "link": "Phase 2",
        "text": "Regional bioeconomy vision ({link}): use as guide to draft activities in the roadmap that ultimately will fulfil the vision."
      },
      "paragraph2": {
        "link1": "Step 2, Phase 3",
        "text": "Determined priority areas and their main goals ({link1}): use to secure that roadmap activities fulfil the main and specific goals for each defined priority areas."
      },
      "paragraph3": {
        "link": "Step 1, Phase 4",
        "text": "Derived specific goals, corresponding actions and institutions responsible of their implementation ({link})"
      },
      "paragraph4": {
        "link": "Step 2, Phase 4",
        "text": "Assigned policy and financial mechanisms for the planned actions ({link})"
      },
      "paragraph5": "Feedback from participatory approach. ",
      "title": "Recommended resources: "
    },
    "title": "Step 3: Draft roadmap of the regional bioeconomy strategy",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "Phase4Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      bestPractisesLink: "",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
